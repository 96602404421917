import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://ce5a33df2beaf194ba14b40e4d5df962@o19974.ingest.us.sentry.io/4507133655711744",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});